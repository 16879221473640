import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../api/form_api";
import { useParams } from "react-router-dom";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];

const endpointAdd = "productaynaart/createProduct"; // Ürün ekleme endpoint'i
const endpointUpdate = "productaynaart/updateProduct"; // Ürün güncelleme endpoint'i

function FormPage() {
  const { id } = useParams();
  const location = useLocation();
  const formApi = new FormApi(
    location.state && location.state.product ? endpointUpdate : endpointAdd
  );

  const product = location.state ? location.state.product : null;
  const categoryCode = location.state ? location.state.categoryCode : null;
  const isEditMode = !!product;

  const [form, setForm] = useState({
    code: isEditMode ? product.code : "",
    name: isEditMode ? product.name : "",
    img: isEditMode ? product.img : [],
    info: isEditMode ? product.info : "",
    metaDesc: isEditMode ? product.metaDesc : "",
    metaKeywords: isEditMode ? product.metaKeywords : "",
  });

  const [validation, setValidation] = useState({
    code: "",
    name: "",
    img: "",
    info: "",
    metaDesc:"",
    metaKeywords:""
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    setForm((prevState) => ({ ...prevState, img: [...prevState.img, ...files] }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    form.img.forEach((file, index) => {
      formData.append("img", file);
    });

    formData.append("name", form.name);
    formData.append("code", form.code);
    formData.append("info", form.info);
    formData.append("metaDesc", form.metaDesc);
    formData.append("metaKeywords", form.metaKeywords);
    formData.append("categoryCode", categoryCode);

    try {
      if (isEditMode) {
        formData.append("categoryCode", categoryCode);
      }

      const result = await formApi.addPage(formData);

      if (isEditMode) {
        alertify.success("Ürün güncellendi.", 5000);
        setTimeout(function() {
          window.location.href = "/urunler";
        }, 2000);
      } else {
        alertify.success("Ürün eklendi.", 5000);
        setTimeout(function() {
          window.location.href = "/urunler";
        }, 2000);
      }
    } catch (e) {
      alertify.error("İşlem başarısız oldu..", 1000);
    }
  };

  return (
    <div className="container mt-3 pb-5">
      <h4>{isEditMode ? "Ürün Düzenleme Paneli" : "Ürün Ekleme Paneli"}</h4>
      <hr />
      <Form onSubmit={onSubmitHandler}>
        <Label>
          <b>Ürün Fotoğrafı</b>
        </Label>
        <br/>
        <input type="file" accept="image/*" multiple onChange={handleImageUpload} />

        <br />
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="code">
                <b>Ürün Numarası</b>
              </Label>
              <Input
                id="code"
                name="code"
                placeholder="Lütfen ürün numarasını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.code}
                min={0}
                readOnly={isEditMode}
                valid={isEditMode ? true : validation.code === "T"}
                invalid={isEditMode ? false : validation.code === "F"}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">
                <b>Ürün Adı</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen ürün adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={isEditMode ? true : validation.name === "T"}
                invalid={isEditMode ? false : validation.name === "F"}
              />
            
            </FormGroup>
            <FormGroup>
              <Label for="metaDesc">
                <b>Ürün Meta Description</b>
              </Label>
              <Input
                id="metaDesc"
                name="metaDesc"
                placeholder="Lütfen ürün meta description giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaDesc}
                valid={isEditMode ? true : validation.metaDesc === "T"}
                invalid={isEditMode ? false : validation.metaDesc === "F"}
              />
            
            </FormGroup>
            <FormGroup>
              <Label for="metaKeywords">
                <b>Ürün Meta Keywords</b>
              </Label>
              <Input
                id="metaKeywords"
                name="metaKeywords"
                placeholder="Lütfen ürün meta keywords giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaKeywords}
                valid={isEditMode ? true : validation.metaKeywords === "T"}
                invalid={isEditMode ? false : validation.metaKeywords === "F"}
              />
            
            </FormGroup>
          </div>
        </div>
        <Label for="info">
          <b>Ürün Açıklaması</b>
        </Label>
        <CKEditor
          name="info"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.info !== "") editor.setData(form.info);
          }}
          onChange={(event, editor) => onChangeHandler("info", editor.getData())}
        />
        <br />
        <Button color="dark" type="submit" onClick={onSubmitHandler}>
          {isEditMode ? "Güncelle" : "Ekle"}
        </Button>
      </Form>
    </div>
  );
}

export default FormPage;
