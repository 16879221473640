import React, { useState, useEffect } from "react";
import { Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Paginator from "../components/Paginator";
import WidgetsDropdown from "../components/WidgetsDropdown";
import alertify from "alertifyjs";

// import Zoomer from "../components/Zoom";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../api/form_api";
import ReactHtmlParser from "react-html-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
const endpoint = "productaynaart";

function Home() {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [productDetail, setProductDetail] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product,categoryCode) => {
    navigate("/form/" + product.code, { state: { product,categoryCode } });
  };

  const onAddProducts = (categoryCode)=>{
    navigate("/form/" + categoryCode,{state:{categoryCode}})
  }

  const deleteProduct = (e, product, categoryCode) => {
    e.preventDefault();
    console.log("category code  " + categoryCode);
    alertify.confirm(
      "Silme İşlemi",
      `${product.code} nolu ürünü silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { categoryCode: categoryCode, productCode: product.code };
          const result = await formApi.deleteProduct(obj);
          if (result) alertify.success("Ürün silindi.", 4000);
          setTimeout(function () {
            window.location.href = "/urunler";
          }, 2000);
        } catch (e) {
          alertify.error("Ürün silinemedi..", 1000);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 1000);
      }
    );
  };

  const goEditCategory = (product) => {
    navigate("/form3", { state: { product } });
  };

  const deleteCategory = async (e, product) => {
    e.preventDefault();

    console.log("Delete Request Data: ", { categoryCode: product.code }); // Verinin doğru şekilde gönderildiğini kontrol etmek için

    alertify.confirm(
      "Silme İşlemi",
      `${product.code} nolu kategoriyi silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { categoryCode: product.code };
          const result = await formApi.deleteCategory(obj);
          if (result) alertify.success("Kategori silindi.", 4000);
          setTimeout(function () {
            window.location.href = "/urunler";
          }, 2000);
        } catch (e) {
          alertify.error("Kategori silinemedi..", 1000);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 1000);
      }
    );
  };

  const showDetail = (product) => {
    setProductDetail(product);
    toggle();
  };

  const getCategoryNames = () => {
    const categoryNames = products.map((product) => product.category.name);
    return [...new Set(categoryNames)]; // Tekrar eden kategori isimlerini kaldır
  };

  useEffect(() => {
    (async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(res);
      setProductsAll(res);
    })();
  }, []);

  return (
    <div className="main p-3">
      {/* <Zoomer toggle={toggle} modal={modal} product={productDetail} /> */}
      <div className="d-flex justify-content-between p-3">
        <Button
          style={{ background: "black" }}
          size="sm"
          onClick={() => navigate("/form3")}
        >
          Kategori Ekle
        </Button>
      </div>
      <div className="container-fluid">
      <Accordion>
  {products.map((product, index) => (
    <AccordionItem key={index}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-8">
              <h2><b>{product.category?.name}</b></h2><br />
              <h6>Kategori Meta Description: <b>{product.category?.metaDesc}</b></h6>
              <h6>Kategori Meta Keywords: <b>{product.category?.metaKeywords}</b></h6><br />
              {ReactHtmlParser(product.category?.info)}
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
              <img
                src={`https://api.aynaart.com.tr/uploads/${product.category?.img}`}
                height={250}
              />
            </div>
            <div>
              <tr>
                <td className="option">
                  <BiEdit onClick={(e) => goEditCategory(product.category)} />
                  <AiFillDelete onClick={(e) => deleteCategory(e, product.category)} />
                </td>
              </tr>
            </div>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="row">
          <div className="d-flex justify-content-between p-3">
            <Button
              style={{ background: "black" }}
              size="sm"
              onClick={() => onAddProducts(product.category.code)}
            >
              Ürün Ekle
            </Button>
          </div>
          <div className="table-scroll col-12">
            {product.category?.products.length > 0 ? (
              <Table striped bordered hover style={{ background: "white" }}>
                <thead>
                  <tr className="text-bold text-center">
                    <th>Ürün No</th>
                    <th>Ürün Görseli</th>
                    <th>Ürün Adı</th>
                    <th>Ürün Meta Description</th>
                    <th>Ürün Meta Keywords</th>
                    <th>Ürün Açıklaması</th>
                    <th>Seçenekler</th>
                  </tr>
                </thead>
                <tbody>
                  {product.category?.products.map((product2, index) => (
                    <tr
                      key={index}
                      className="align-middle text-center"
                      onClick={(e) => showDetail(product2)}
                    >
                      <td>{product2.code}</td>
                      <td>
                      {product2.img.map((imgSrc, imgIndex) => (
              <img
                key={imgIndex}
                src={`https://api.aynaart.com.tr/uploads/${imgSrc}`}
                height={50}
                style={{ marginRight: "10px" }}
              />
            ))}
                      </td>
                      <td>{product2.name}</td>
                      <td>{product2.metaDesc}</td>
                      <td>{product2.metaKeywords}</td>
                      <td>{ReactHtmlParser(product2.info)}</td>
                      <td className="option">
                        <BiEdit onClick={(e) => goEditProduct(product2,product.category.code)} />
                        <AiFillDelete
                          onClick={(e) =>
                            deleteProduct(e, product2, product.category.code)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>Ürün bulunamadı.</p>
            )}
          </div>
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  ))}
</Accordion>

      </div>
    </div>
  );
}
export default Home;
