import React, { useState, useEffect } from "react";
import { Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Paginator from "../components/Paginator";
import alertify from "alertifyjs";
import Zoomer from "../components/Zoom";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../api/form_api";
import ReactHtmlParser from "react-html-parser";

const endpoint = "blog";

function Home() {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [productDetail, setProductDetail] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/formBlog", { state: { product } });
  };
  const deleteProduct = (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product.blog_no} nolu blogu silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id, img: product.img };
          const result = await formApi.deleteImage(obj);
          if (result) alertify.success("Blog silindi..", 2.5);
        } catch (e) {
          alertify.error("Blog silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };
  const showDetail = (product) => {
    setProductDetail(product);
    toggle();
  };
  const loadPage = (products) => {
    // Ürünleri 'blog_no' özelliğine göre sırala
    const sortedProducts = products.sort((a, b) => {
      const numA = parseInt(a.blog_no, 10); // Parse the numeric part of blog_no
      const numB = parseInt(b.blog_no, 10);
  
      // Use numeric comparison if both are valid numbers
      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }
  
      // Fallback to string comparison if one or both are not valid numbers
      return a.blog_no.localeCompare(b.blog_no);
    });
  
    return sortedProducts.map((product) => (
      <tr
        className="align-middle text-center"
        key={product.blog_no}
        onClick={(e) => showDetail(product)}
      >
        <td>
  {product.img ? (
    <img
      src={`https://api.aynaart.com.tr/uploads/${product.img}`}
      height={50}
    />
  ) : (
    "Henüz bu bloga ait görsel yoktur"
  )}
</td>

  
        <td>{product.blog_no}</td>
        <td>{product.name}</td>
        <td>{product.metaDesc}</td>
        <td>{product.metaKeywords}</td>
        <td>{product.info && ReactHtmlParser(product.info.slice(0, 120))}</td>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };
  
  
  const onSearchHandler = (e) => {
    e.preventDefault();
    const filteredProducts = [];
    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (key === "name" && value.toLowerCase().includes(search))
          filteredProducts.push(product);
      });
    });
    setTempProducts(loadPage(filteredProducts));
  };
  useEffect(() => {
    (async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    })();
  }, []);

  return (
    <div className="main p-3">
  
      <div className="d-flex justify-content-between p-3">
        <Button
          style={{ background: "black" }}
          size="sm"
          onClick={() => navigate("/formBlog")}
        >
          Blog Ekle
        </Button>
        <form className="d-flex">
          <Input
            type="search"
            name="search"
            id="exampleSearch"
            placeholder="Başlığa göre arayın..."
            value={search}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                setProducts(productsAll);
                setTempProducts(loadPage(productsAll));
              } else {
                let filteredProducts = [];
                products.forEach((product) => {
                  Object.entries(product).forEach(([key, value]) => {
                    if (
                      key === "name" &&
                      value.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                      filteredProducts.push(product);
                  });
                });
                setProducts(filteredProducts);
                setTempProducts(loadPage(filteredProducts));
              }
              setSearch(e.target.value);
            }}
          />
          <Button
            type="submit"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              background: "black",
            }}
            onClick={(e) => onSearchHandler(e)}
          >
            Ara
            <i className="fa fa-search"></i>
          </Button>
        </form>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="table-scroll col-12">
            <Table striped bordered hover style={{ background: "white" }}>
              <thead>
                <tr className="text-bold text-center">
                  <th>Görsel</th>
                  <th>Blog No</th>
                  <th>Blog Adı</th>
                  <th>Blog Meta Description</th>
                  <th>Blog Meta Keywords</th>
                  <th>İçerik</th>
                  <th>Seçenekler</th>
                </tr>
              </thead>
              <tbody>
                {tempProducts.length === 0 ? (
                  <p className="text-danger p-2">Blog bulunamadı</p>
                ) : (
                  tempProducts.slice(0, 10)
                )}
              </tbody>
            </Table>
            {products ? (
              <Paginator
                len={products.length}
                data={products}
                setTempProducts={setTempProducts}
                loadPage={loadPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
