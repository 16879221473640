import React from "react";
import { Route, Routes } from "react-router-dom";

import NavBar from "./components/Navbar";
import Home from "./Pages/Home";
import Home2 from "./Pages/Home2";
import FormPage from "./Pages/FormPage";
import Login from "./Pages/Login";
import FormPage2 from "./Pages/FormPage2";
import FormPage3 from "./Pages/FormPage3";
import Homepage from "./Pages/Homepage";
import UseAuth from "./hooks/UseAuth";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <UseAuth>
        {" "}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<NavBar />}>
            <Route path="/anasayfa" element={<Homepage />} />
            <Route path="/urunler" element={<Home />} />
            <Route path="/blog" element={<Home2 />} />
            <Route path="form/:id" element={<FormPage />} />
            <Route path="formBlog" element={<FormPage2 />} />
            <Route path="form3" element={<FormPage3 />} />
          </Route>
        </Routes>
        <Footer />
      </UseAuth>
    </div>
  );
}

export default App;
