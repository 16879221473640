import { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupText,
  Button,
  InputGroup,
} from "reactstrap";
import { lengthError, typeError } from "../form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import Slider from "../components/Slider";
import { useLocation } from "react-router-dom";
import { FormApi } from "../api/form_api";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "blog";
function FormPage() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    blog_no: "",
    info: "",
    name: "",
    img: "",
    metaDesc: "",
    metaKeywords: ""
  });

  const [validation, setValidation] = useState({
    blog_no: "",
    info: "",
    name: "",
    img: "",
    metaDesc: "",
    metaKeywords: ""
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // setIsSubmitting(true);

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("img", form.img);
    formData.append("name", form.name);
    formData.append("blog_no", form.blog_no);
    formData.append("info", form.info);
    formData.append("metaDesc", form.metaDesc);
    formData.append("metaKeywords", form.metaKeywords);

    try {
      const rst = await formApi.addPage(formData);
      // console.log(rst);
      alertify.success(
        "Blog eklendi..",
        ((window.location.href = "/blog"), 1000),
        2.5
      );
    } catch (e) {
      alertify.error("Blog eklenemedi..", 1000);
    }
    // setIsSubmitting(false);
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      alertify.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("img", form.img);
      formData.append("name", form.name);
      formData.append("blog_no", form.blog_no);
      formData.append("info", form.info);
      formData.append("metaDesc", form.metaDesc);
      formData.append("metaKeywords", form.metaKeywords);

      try {
        const rst = await formApi.updatePageBlog(formData);
        // console.log(rst);
        alertify.success("Blog güncellendi.", 5000);
        setTimeout(function() {
          window.location.href = "/blog";
        }, 2000);
      } catch (e) {
        alertify.error("Düzenlemeler yapılamadı..", 2.5);
      }
    }
  };
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
      });
  }, []);
  // console.log(form);

  /*   useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        if (key === "status" || key === "sales")
          document.getElementById(value).setAttribute("checked", "true");
      });
  }, []); */
  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  return (
    <div className="container mt-3 pb-5">
      <h4>Blog Ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        <Label>
        <b>Blog Fotoğrafı(Fotografı seçtikten sonra lütfen bulut iconuna tıklayın.)</b>
        </Label>

        <Slider imageUpload={imageUpload} data={dataSources} />
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="blog_no">
                <b>Blog Numarası</b>
              </Label>
              <Input
                id="blog_no"
                name="blog_no"
                placeholder="Lütfen blog numarasını giriniz..."
                type="number"
                valid={validation.blog_no === "T"}
                invalid={validation.blog_no === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.blog_no}
                min={0}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="name">
                <b>Blog Adı</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen blog adını  giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="metaDesc">
                <b>Blog Meta Description</b>
              </Label>
              <Input
                id="metaDesc"
                name="metaDesc"
                placeholder="Lütfen blog meta description giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaDesc}
                valid={validation.metaDesc === "T"}
                invalid={validation.metaDesc === "F"}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="metaKeywords">
                <b>Blog Meta Keywords</b>
              </Label>
              <Input
                id="metaKeywords"
                name="metaKeywords"
                placeholder="Lütfen blog meta keywords giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaKeywords}
                valid={validation.metaKeywords === "T"}
                invalid={validation.metaKeywords === "F"}
              />
              {lengthError}
            </FormGroup>
          </div>
        </div>

        <Label for="info">
          <b>İçerik</b>
        </Label>
        <CKEditor
          name="info"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.info !== "") editor.setData(form.info);
          }}
          onChange={(event, editor) =>
            onChangeHandler("info", editor.getData())
          }
        />
        {validation.info === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>

        <Button
          type="submit"
          style={{
            background: "white",
            color: "black",
          }}
          onClick={(e) => onUpdateHandler(e)}
        >
          Güncelle
        </Button>
      </Form>
    </div>
  );
}

export default FormPage;
