import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { lengthError, typeError } from "../form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import Slider from "../components/Slider";
import { useLocation } from "react-router-dom";
import { FormApi } from "../api/form_api";
import { useParams } from "react-router-dom";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];

const endpointAdd = "productaynaart/createCategory"; // Kategori ekleme endpoint'i
const endpointUpdate = "productaynaart/updateCategory"; // Kategori güncelleme endpoint'i

function FormPage() {
  const { id } = useParams();
  const location = useLocation();
  const formApi = new FormApi(
    location.state ? endpointUpdate : endpointAdd
  ); // Endpoint'i belirleme

  const product = location.state ? location.state.product : null;

  const isEditMode = !!product;

  // Form verilerinin başlangıç değerleri
  const [form, setForm] = useState({
    code: isEditMode ? product.code : "",
    name: isEditMode ? product.name : "",
    img: isEditMode ? product.img : "",
    info: isEditMode ? product.info : "",
    metaDesc: isEditMode ? product.metaDesc : "",
    metaKeywords: isEditMode ? product.metaKeywords : "",
  });

  const [validation, setValidation] = useState({
    code: "",
    name: "",
    img: "",
    info: "",
    metaDesc:"",
    metaKeywords:""
  });

  // Veri geçerliliğini kontrol eden fonksiyon
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  // Form alanlarının değişimini takip eden fonksiyon
  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  // Kategori ekleme veya güncelleme işlemini gerçekleştiren fonksiyon
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("img", form.img);
    formData.append("name", form.name);
    formData.append("code", form.code);
    formData.append("info", form.info);
    formData.append("metaDesc", form.metaDesc);
    formData.append("metaKeywords", form.metaKeywords);

    try {
      if (isEditMode) {
        formData.append("_id", product._id); // Güncelleme için _id ekleyin
      }

      const result = await formApi.addPage(formData); // Ekleme veya güncelleme işlemini gerçekleştirin

      if (isEditMode) {
        alertify.success("Kategori güncellendi.", 5000);
        setTimeout(function() {
          window.location.href = "/urunler";
        }, 2000);
      } else {
        alertify.success("Kategori eklendi.", 5000);
        setTimeout(function() {
          window.location.href = "/urunler";
        }, 2000);
      }
      
    } catch (e) {
      alertify.error("İşlem başarısız oldu..", 1000);
    }
  };

  // Görsel yükleme işlemi için fonksiyon
  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  return (
    <div className="container mt-3 pb-5">
      <h4>{isEditMode ? "Kategori Düzenleme Paneli" : "Kategori Ekleme Paneli"}</h4>
      <hr />
      <Form onSubmit={onSubmitHandler}>
        <Label>
          <b>Kategori Fotoğrafı(Fotografı seçtikten sonra lütfen bulut iconuna tıklayın.)</b>
        </Label>
        <Slider imageUpload={imageUpload} data={dataSources} />
        <br />
        <div className="row col-12">
          <div className="col-12">
          <FormGroup>
  <Label for="code">
    <b>Kategori Numarası</b>
  </Label>
  <Input
    id="code"
    name="code"
    placeholder="Lütfen kategori numarasını giriniz..."
    type="text"
    onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
    value={form.code}
    min={0}
    readOnly={isEditMode}
    valid={isEditMode ? true : validation.code === "T"}
    invalid={isEditMode ? false : validation.code === "F"}
  />
</FormGroup>


            <FormGroup>
              <Label for="name">
                <b>Kategori Adı</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen kategori adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
              />
              {lengthError}
            </FormGroup>
         
            <FormGroup>
              <Label for="metaDesc">
                <b>Kategori Meta Description</b>
              </Label>
              <Input
                id="metaDesc"
                name="metaDesc"
                placeholder="Lütfen kategori meta description giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaDesc}
                valid={validation.metaDesc === "T"}
                invalid={validation.metaDesc === "F"}
              />
              {lengthError}
            </FormGroup>
           
            <FormGroup>
              <Label for="metaKeywords">
                <b>Kategori Meta Keywords</b>
              </Label>
              <Input
                id="metaKeywords"
                name="metaKeywords"
                placeholder="Lütfen kategori meta keywords giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.metaKeywords}
                valid={validation.metaKeywords === "T"}
                invalid={validation.metaKeywords === "F"}
              />
              {lengthError}
            </FormGroup>
          </div>
        </div>
        <Label for="info">
          <b>Kategori Açıklaması</b>
        </Label>
        <CKEditor
          name="info"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.info !== "") editor.setData(form.info);
          }}
          onChange={(event, editor) => onChangeHandler("info", editor.getData())}
        />
        <br />
        <Button color="dark" type="submit" onClick={onSubmitHandler}>
          {isEditMode ? "Güncelle" : "Ekle"}
        </Button>
      </Form>
    </div>
  );
}

export default FormPage;






